import * as React from 'react'
import { graphql } from 'gatsby'
import {
  StoryblokComponent,
  storyblokEditable,
  useStoryblokState,
} from 'gatsby-source-storyblok'

import { createContactFormBlok } from '../utils'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { useSiteMetadata } from '../hooks/useSiteMetadata'

const ContactSuccessPage = ({ pageContext, location }) => {
  let story = pageContext?.story
  story = useStoryblokState(story)
  const blok = story.content
  const site = useSiteMetadata()
  const language = pageContext?.language
  const isDefaultLang = language === site.defaultLanguage
  const contactBackUrl = location?.state?.contactBackUrl
  const homeUrl = `/${isDefaultLang ? '' : language}`

  const contactFormSectionBlok = createContactFormBlok({
    context: pageContext,
  })

  const components = [
    {
      _uid: 'hero-contact',
      component: 'HeroContact',
      title: blok.title,
      copy: blok.copy,
      image: blok.image,
      textColor: blok.textColor,
      homeUrl: homeUrl,
      backUrl: contactBackUrl,
      gotoHomeLabel: blok.gotoHomeLabel,
      backButtonLabel: blok.backButtonLabel,
    },
    ...story?.content?.body,
  ].map((blok, index) => {
    let finalBlok = {
      ...blok,
      ...(blok.component === 'ContactFormSection'
        ? {
            contactForm: {
              ...contactFormSectionBlok,
              variant: 'standalone',
            },
          }
        : undefined),
      sectionIndex: index,
      isFirstSection: index === 0,
    }

    return <StoryblokComponent blok={finalBlok} key={finalBlok._uid} />
  })

  return (
    <Layout
      context={pageContext}
      location={location}
      floatingSiteNavigation={true}
    >
      <main {...storyblokEditable(story?.content)}>{components}</main>
    </Layout>
  )
}

export default ContactSuccessPage

export const Head = ({ pageContext, location }) => {
  const storyContent = pageContext?.story?.content || {}

  return (
    <Seo
      location={location}
      seo={storyContent.seo}
      noindex={storyContent.noindex}
      ignorePageTitleTemplate={storyContent.ignorePageTitleTemplate}
      siteConfig={pageContext?.siteConfig}
    />
  )
}

export const query = graphql`
  query ($langCode: String!) {
    locales: allLocale(filter: { language: { eq: $langCode } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
